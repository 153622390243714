import React from "react";
import Cover from "../images/404.jpg";

const NotFound = () => {
  return (
    <section className="kc__not-found">
      <div className="container">
        <figure className="kc__not-found__cover">
          <img src={Cover} alt="not-found" />
          <figcaption className="kc__not-found__credits">
            <span>
              Photo by{" "}
              <a href="https://unsplash.com/@woodies11?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                Romson Preechawit
              </a>{" "}
              on{" "}
              <a href="https://unsplash.com/s/photos/error?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                Unsplash
              </a>
            </span>
          </figcaption>
        </figure>
      </div>
    </section>
  );
};

export default NotFound;
