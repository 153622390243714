import React from "react";
import "./App.css";
import Home from "./Components/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import ProjectPage from "./Components/ProjectPage";
import AboutPage from "./Components/AboutPage";
import "./styles/reset.css";
import "./styles/main.sass";
import Helmet from "react-helmet";
import { KCProvider } from "./context/ThemeContext";
import NotFound from "./Components/NotFound";

function App() {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Karim Cheurfi - Photogtaphy Portfolio"
        defaultTitle="Karim Cheurfi - Photography Portfolio"
      ></Helmet>
      <main className="kc">
        <BrowserRouter>
          <KCProvider>
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/series/:project_id" component={ProjectPage} />
              <Route component={NotFound} />
            </Switch>
          </KCProvider>
        </BrowserRouter>
      </main>
    </React.Fragment>
  );
}

export default App;
