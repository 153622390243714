import React from "react";

const Loading = () => {
  return (
    <section className="loading content">
      <p className="loading__logo">カリム</p>
    </section>
  );
};

export default Loading;
