import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Flickr from "flickr-sdk";
import Error from "./Error";
import Loading from "./Loading";
import Helmet from "react-helmet";

const Home = () => {
  const [projects, setProjects] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const flickr = new Flickr(process.env.REACT_APP_FLICKR_API_KEY);

  const titleCase = (str) => {
    // SUPER STINKY BUT HEY
    if (str === "7 PM in PNM") {
      return str;
    }
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const pics = await flickr.photosets.getList({
          user_id: process.env.REACT_APP_FLICKR_USER_ID,
          primary_photo_extras: "url_o",
        });
        setProjects(pics.body.photosets.photoset);
        setLoading(false);
      } catch {
        setApiError(true);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [flickr.photosets]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <React.Fragment>
        {apiError ? (
          <Error />
        ) : (
          <React.Fragment>
            <Helmet>
              <title>Home</title>
              <meta
                name="description"
                content="Street photographer, visual documentalist, social commentator. Based in Paris."
              />
            </Helmet>
            <section className="home content">
              <ul className="home__projects">
                {projects &&
                  projects.map((p, idx) => (
                    <li key={idx} className="home__projects__item">
                      <Link
                        className="home__projects__item__link"
                        to={`/series/${p.id}`}
                        data-text={`${titleCase(p.title._content)}`}
                      >
                        {p.title._content}
                      </Link>
                    </li>
                  ))}
              </ul>
            </section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

export default Home;
