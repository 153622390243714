import React from "react";
import Helmet from "react-helmet";
import Portrait from "../images/portrait.jpg";
import { ReactComponent as EmailIcon } from "./icons/mail.svg";
import { ReactComponent as InstaIcon } from "./icons/instagram.svg";

const AboutPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>About Me</title>
        <meta
          name="description"
          content="Learn more about Karim's journey into documenting the world through photography."
        />
      </Helmet>
      <section className="about content">
        <div className="container">
          <div className="about__portrait">
            <img src={Portrait} alt="karim cheurfi" />
          </div>
          <article className="about__content">
            <p>
              Made in Paris, I grew up in the eastern suburbs of the french
              capital. I started taking pictures at the age of 15 with a red
              Kodak point and shoot I bought with my first salary.
            </p>
            <p>
              I would describe my photography as <strong>genuine</strong>. None
              of my shots are staged. I prefer to capture life as it happens,
              let things take their normal course.
            </p>
            <p>
              <strong>Visual documentary</strong> is the core of what I do. From
              architecture to the quirckiness of city life, I try to give a
              detailed account of the world around me and its evolution.
            </p>
            <p>
              The other aspect of my work focuses on{" "}
              <strong>meaningful social commentary</strong>. In some of my
              photoseries, I attempt to show the true reality behind what
              Baudrillard dubbed "the simulation".
            </p>
            <p>
              Currently, I'm actively working on the creation of a magazine
              (online and print) as well as a podcast where I interview other
              photographers (mostly in French) about our shared passion.
            </p>

            <p>I hope you like what you see.</p>

            <p>Karim.</p>
          </article>
          <div className="about__contact">
            <a
              className="about__contact__email contact-link"
              href="mailto:hello@karimcheurfi.com"
            >
              <EmailIcon />
              <span>hello@karimcheurfi.com</span>
            </a>
            <a
              className="about__contact__insta contact-link"
              href="https://instagram.com/zabanaa_"
            >
              <InstaIcon />
              <span>@zabanaa_</span>
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutPage;
