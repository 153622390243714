import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as InfoIcon } from "./icons/info.svg";

const Sidebar = () => {
  return (
    <header className="kc__header">
      <div className="kc__header__logo">
        <Link to="/">KC</Link>
      </div>

      <Link className="kc__header__link" to="/about">
        <InfoIcon />
      </Link>
    </header>
  );
};

export default Sidebar;
