import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <section className="error content">
      <div className="container">
        <header className="error__header">
          <h2 className="error__header__title">An error occured ...</h2>
        </header>
        <article className="error__text">
          <p>
            While the issue is being resolved, I encourage you to{" "}
            <Link to="/about">read my story</Link> or visit my{" "}
            <a href="https://flickr.com/people/167034947@N08/">
              flickr profile.
            </a>
          </p>

          <p>
            And you like what you see,{" "}
            <a href="https://instagram.com/zabanaa_">follow me on instagram.</a>
          </p>

          <p>Sorry for the inconvenience.</p>
        </article>
      </div>
    </section>
  );
};

export default Error;
