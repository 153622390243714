import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Flickr from "flickr-sdk";
import Error from "./Error";
import Loading from "./Loading";
import Helmet from "react-helmet";

const ProjectPage = (props) => {
  const [project, setProject] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [apiError, setAPIError] = useState(false);
  const [loading, setLoading] = useState(true);
  const flickr = new Flickr(process.env.REACT_APP_FLICKR_API_KEY);
  const flickrUserId = process.env.REACT_APP_FLICKR_USER_ID;

  useEffect(() => {
    const fetchProject = async () => {
      const projectId = props.match.params.project_id;
      try {
        const project = await flickr.photosets.getInfo({
          user_id: flickrUserId,
          photoset_id: projectId,
        });
        const photos = await flickr.photosets.getPhotos({
          user_id: flickrUserId,
          photoset_id: projectId,
          extras: "url_m",
        });
        setProject({
          title: project.body.photoset.title._content,
          description: project.body.photoset.description._content,
          date: new Date(
            project.body.photoset.date_create * 1000
          ).getFullYear(),
          totalPhotos: project.body.photoset.count_photos,
          albumId: project.body.photoset.id,
        });
        setPhotos(photos.body.photoset.photo);
        setLoading(false);
      } catch {
        setAPIError(true);
        setLoading(false);
      }
    };
    fetchProject(); // eslint-disable-next-line
  }, [flickr.photosets, props.match.params.project_id]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <React.Fragment>
        {apiError ? (
          <Error />
        ) : (
          <React.Fragment>
            <Helmet>
              <title>{project.title}</title>
              <meta name="description" content={project.description} />
            </Helmet>
            <section className="project content">
              <div className="container">
                {project && (
                  <header className="project__header">
                    <h2 className="project__header__title">{project.title}</h2>
                    <p className="project__header__description">
                      {project.description}
                    </p>
                    <ul className="project__header__meta">
                      <li>Year: {project.date}</li>
                      <li>{project.totalPhotos} Photos</li>
                      <li>
                        <a
                          href={`https://www.flickr.com/photos/${flickrUserId}/albums/${project.albumId}`}
                        >
                          View on Flickr
                        </a>
                      </li>
                    </ul>
                  </header>
                )}
                <ul className="project__photos">
                  {photos &&
                    photos.map((p, idx) => (
                      <li key={idx} className="project__photos__item">
                        <figure>
                          <img alt="" src={p.url_m} />
                        </figure>
                      </li>
                    ))}
                </ul>
              </div>
            </section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

export default ProjectPage;
