import React from "react";
import { useState } from "react";

export const KCContext = React.createContext();

export const KCProvider = ({ children }) => {
  const [isMenuActive, setMenu] = useState(false); // menu disabled by default
  const context = {
    isMenuActive,
    setMenu,
  };

  return <KCContext.Provider value={context}>{children}</KCContext.Provider>;
};
